<template>
    <Card :status-text="statusText" :status-color="statusColor" @click="onAdditionCard()">
        <template #headline>{{ title }}</template>
        <p class="columns">
            <span>
                {{ description }}
            </span>
            <span>
                <small>Pris</small><br>
                <strong class="larger">{{ formatNumberToPrice(price) }}</strong>
            </span>
        </p>
        <ActionButton v-if="!status" :small="true" label="Send kontakt" @click="onAdditionCard('send')"/>
    </Card>
</template>

<script>
import Card from '@/components/Card.vue';
import methods from '@/utils/methods';
import ActionButton from '@/components/buttons/ActionButton.vue';
import useState from '@/store';

export default {
    name: 'AdditionCard',
    components: {
        Card,
        ActionButton,
    },
    mixins: [methods],
    props: {
        contract: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        price: {
            type: Number,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        file: {
            type: Object,
            default: null,
        },
        days: {
            type: Number,
            default: null,
        },
        created: {
            type: Object,
            default: null,
        },
        status: {
            type: Object,
            default: null,
        },
    },
    setup() {
        const { setModalData } = useState();
        return { setModalData };
    },
    computed: {
        statusText() {
            return this.getContractStatusObject(this.status?.state).label;
        },
        statusColor() {
            return this.getContractStatusObject(this.status?.state).color;
        },
    },
    methods: {
        onAdditionCard(action = null) {
            const data = this.removeNullValues(this.$props);
            this.setModalData(data);
            const router = { name: 'view-addition', params: { contract: this.contract, id: data.id } };
            if (action) router.query = { action };
            this.$router.push(router);
        },
    },
};
</script>
