<template>
    <Main :show-title="false" :grid="true">
        <section class="progress naked">
            <h2>Budget</h2>
            <header class="card">
                <p class="columns">
                    <span>
                        <strong class="percent">
                            <Placeholder v-if="budget.isLoading" :width="100" :border-radius="10" style="vertical-align: top; height: 0.7em;"/>
                            <template v-else>{{ budget.percent }}%</template>
                        </strong>
                    </span>
                    <span>
                        <small>Samlet budget</small><br>
                        <strong class="larger">
                            <Placeholder v-if="project.isLoading" :width="60"/>
                            <template v-else>{{ formatNumberToPrice(project.budget) }}</template>
                        </strong><br>
                        <small class="space-top">Rest til betaling</small><br>
                        <strong class="larger">
                            <Placeholder v-if="budget.isLoading" :width="50"/>
                            <template v-else>{{ formatNumberToPrice(budget.rest) }}</template>
                        </strong>
                    </span>
                </p>
                <p class="explainer">
                    Procentdelen angiver dit resterende budget (inkl. {{ project.unforeseenExpenses }}% uforudsigelige udgifter
                </p>
                <Placeholder v-if="project.isLoading" :width="100" :height="15" :border-radius="30" style="margin-top: 1.3rem;"/>
                <progress v-else :value="budget.percent" max="100">{{ budget.percent }}%</progress>
            </header>
        </section>

        <section v-if="question.isLoading || !!question.docs.recents.length">
            <h2>Spørgsmål</h2>
            <header class="card labels">
                <template v-if="question.isLoading">
                    <Placeholder v-for="index in 2" :key="index" element="span" :classes="['status-label']" :width="20"/>
                </template>
                <template v-else>
                    <span v-if="question.docs.awaiting.length" class="status-label yellow">Afventer svar ({{ question.docs.awaiting.length }})</span>
                    <span v-if="question.docs.answered.length" class="status-label green">Besvaret ({{ question.docs.answered.length }})</span>
                </template>
            </header>
            <h3>Seneste</h3>
            <CardList :compact="true" v-if="question.isLoading">
                <Placeholder v-for="index in 2" :key="index" element="li" :classes="['card column-1']" :height="61"/>
            </CardList>
            <CardList :compact="true" v-else>
                <QuestionCard v-for="doc in question.docs.recents" :key="doc.id" v-bind="doc" :compact="true" />
            </CardList>
        </section>
        <Empty v-else illustration="questions" :inline="true">
            <template #title>Der er ingen åbne spørgsmål</template>
            <template #description>Stil et nyt
                <router-link to="/questions/create">spørgsmål</router-link>
            </template>
        </Empty>

        <section v-if="contract.isLoading || !!contract.docs.recents.length">
            <h2>Kontrakter</h2>
            <header class="card labels">
                <template v-if="contract.isLoading">
                    <Placeholder v-for="index in 3" :key="index" element="span" :classes="['status-label']" :width="20"/>
                </template>
                <template v-else>
                    <span v-if="contract.docs.drafs.length" :class="`status-label ${getContractStatusObject('').color}`">Kladder ({{ contract.docs.drafs.length }})</span>
                    <span v-if="contract.docs.accepted.length" :class="`status-label ${getContractStatusObject('ACCEPTED').color}`">Godkendte ({{ contract.docs.accepted.length }})</span>
                    <span v-if="contract.docs.sent.length" :class="`status-label ${getContractStatusObject('SENT').color}`">SENDTE ({{ contract.docs.sent.length }})</span>
                    <span v-if="contract.docs.rejected.length" :class="`status-label ${getContractStatusObject('REJECTED').color}`">AFVISTE ({{ contract.docs.rejected.length }})</span>
                </template>
            </header>
            <h3>Seneste</h3>
            <CardList :compact="true" v-if="contract.isLoading">
                <Placeholder v-for="index in 2" :key="index" element="li" :classes="['card column-1']" :height="61"/>
            </CardList>
            <CardList :compact="true" v-else class="contracts">
                <template v-for="doc in contract.docs.recents" :key="doc.id">
                    <ContractCard v-if="doc.company" v-bind="doc" class="compact"/>
                    <AdditionCard v-else v-bind="doc" class="compact"/>
                </template>
            </CardList>
        </section>
        <Empty v-else illustration="contracts" :inline="true">
            <template #title>Ingen kontrakter tilføjet endnu</template>
            <template #description>Tilføj din
                <router-link to="/contracts/create">første</router-link>
                kontrakt
            </template>
        </Empty>

        <section class="progress double">
            <div>
                <h2>Tidsplan</h2>
                <header class="card">
                    <p class="columns">
                        <span>
                            <strong class="percent">
                                <Placeholder v-if="project.isLoading" :width="100" :border-radius="10" style="vertical-align: top; height: 0.7em;"/>
                                <template v-else>{{ timeline.percent }}%</template>
                            </strong>
                        </span>
                        <span>
                            <small>Projekt startdato</small><br>
                            <strong class="larger">
                                <Placeholder v-if="project.isLoading" :width="50"/>
                                <template v-else>{{ formatDateToFnsDate(project.from) }}</template>
                            </strong><br>
                            <small class="space-top">Projekt slutdato</small><br>
                            <strong class="larger">
                                <Placeholder v-if="project.isLoading" :width="50"/>
                                <template v-else>{{ formatDateToFnsDate(project.to) }}</template>
                            </strong>
                        </span>
                    </p>
                    <p class="explainer">
                        Ret projektets slutdato under indstillinger til projektet, hvis dette ikke
                        stemmer overens.
                    </p>
                    <Placeholder v-if="budget.isLoading" :width="100" :height="15" :border-radius="30" style="margin-top: 1.3rem;"/>
                    <progress v-else :value="timeline.percent" max="100">{{ timeline.percent }}%</progress>
                </header>
            </div>
            <div v-if="timeline.isLoading || !!timeline.docs.length">
                <h3>Kommende opgaver</h3>
                <CardList :compact="true" v-if="timeline.isLoading">
                    <Placeholder v-for="index in 2" :key="index" element="li" :classes="['card column-1']" :height="61"/>
                </CardList>
                <CardList :compact="true" v-else>
                    <TimelineCard v-for="doc in timeline.docs" :key="doc.id" v-bind="doc" class="compact with-description" />
                </CardList>
            </div>
            <Empty v-else illustration="timeline" :inline="true">
                <template #title>Der er ingen<br>opgaver i sigte</template>
                <template #description>Tilføj
                    <router-link to="/timeline/create">opgave</router-link>
                </template>
            </Empty>
        </section>

        <section class="project">
            <Placeholder v-if="project.isLoading" :width="100" :border-radius="30" style="height: 100%;"/>
            <template v-else-if="project.image">
                <h2>Projekt<br><small>{{ project.name }}</small></h2>
                <img :src="project.image" alt="">
            </template>
            <ActionButton v-else label="Upload billede" icon="camera" :small="true" @click="$router.push({ name: 'view-project', params: { id: project.id } })"/>
        </section>
    </Main>
</template>

<script>
import Main from '@/components/Main.vue';
import CardList from '@/components/CardList.vue';
import { computed, onMounted, reactive, toRefs } from 'vue';
import Empty from '@/components/Empty.vue';
import {
    additionsCollection,
    budgetsCollection,
    contractsCollection,
    projectsCollection,
    timelineCollection,
} from '@/utils/collections';
import useState from '@/store';
import useUnsubscribe from '@/utils/useUnsubscribe';
import methods from '@/utils/methods';
import { downloadFile } from '@/utils/firebase';
import TimelineCard from '@/components/cards/TimelineCard.vue';
import QuestionCard from '@/components/cards/QuestionCard.vue';
import ContractCard from '@/components/cards/ContractCard.vue';
import Placeholder from '@/components/Placeholder.vue';
import { differenceInDays, isAfter } from 'date-fns';
import useTickets from '@/utils/useTickets';
import ActionButton from '@/components/buttons/ActionButton.vue';
import AdditionCard from '@/components/cards/AdditionCard.vue';

export default {
    name: 'Dashboard',
    components: {
        AdditionCard,
        TimelineCard,
        Main,
        CardList,
        Empty,
        QuestionCard,
        ContractCard,
        Placeholder,
        ActionButton,
    },
    mixins: [methods],
    setup() {
        const { project } = useState();
        const sections = reactive({
            budget: {
                isLoading: true,
                rest: 0,
                percent: 0,
            },
            contract: {
                isLoading: true,
                docs: {
                    all: [],
                    recents: [],
                },
            },
            timeline: {
                isLoading: true,
                docs: [],
                percent: 0,
            },
            project: {
                isLoading: true,
                name: project.value.name,
                image: null,
                budget: 0,
                from: new Date(),
                to: new Date(),
                id: project.value.id,
                unforeseenExpenses: 0,
            },
            question: {
                isLoading: true,
                docs: {
                    recents: [],
                },
            },
        });

        // Budget
        (() => {
            const { docs, isLoading } = useUnsubscribe(budgetsCollection());
            sections.budget.rest = computed(() => {
                const totalBudgetsSum = docs.value.reduce((acc, budget) => acc + (budget.payment.price + (budget?.contract?.addition || 0)), 0);
                const totalPaid = docs.value.reduce((acc, budget) => acc + budget.payment.paid, 0);
                return totalBudgetsSum - totalPaid;
            });
            sections.budget.percent = computed(() => Math.round(((sections.project.budget - sections.budget.rest) / sections.project.budget) * 100));
            sections.budget.isLoading = isLoading;
        })();

        // Contract
        (async () => {
            const contracts = await contractsCollection().get();
            const contractsAndAdditions = contracts.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            await Promise.all(contractsAndAdditions.map(async (contract) => {
                const additions = await additionsCollection(contract.id).get();
                return additions.docs.map((doc) => contractsAndAdditions.push({ id: doc.id, contract: contract.id, ...doc.data() }));
            }));

            sections.contract.docs.all = computed(() => [...contractsAndAdditions].sort((a, b) => b.created - a.created));
            sections.contract.docs.recents = computed(() => sections.contract.docs.all.slice(0, 2));
            sections.contract.docs.drafs = computed(() => sections.contract.docs.all.filter((doc) => !doc.status));
            sections.contract.docs.accepted = computed(() => sections.contract.docs.all.filter((doc) => doc.status?.state === 'ACCEPTED'));
            sections.contract.docs.sent = computed(() => sections.contract.docs.all.filter((doc) => doc.status?.state === 'SENT'));
            sections.contract.docs.rejected = computed(() => sections.contract.docs.all.filter((doc) => doc.status?.state === 'REJECTED'));
            sections.contract.isLoading = false;
        })();

        // Timeline
        (() => {
            const { docs, isLoading } = useUnsubscribe(timelineCollection());
            sections.timeline.docs = computed(() => docs.value.filter((d) => isAfter(d.to?.toDate(), new Date())).sort((a, b) => a.from?.toDate() - b.from.toDate()).slice(0, 3));
            sections.timeline.isLoading = isLoading;
        })();

        // Questions
        (async () => {
            const response = await useTickets(project.value.id);
            const tickets = response.tickets.value.open;
            sections.question.docs.recents = computed(() => tickets.slice(0, 2));
            sections.question.docs.awaiting = tickets.filter((t) => t.statusColor === 'yellow');
            sections.question.docs.answered = tickets.filter((t) => t.statusColor === 'green');
            sections.question.isLoading = false;
        })();

        onMounted(async () => {
            const pro = await projectsCollection().doc(project.value.id).get();
            const projectData = pro.data();
            sections.project.from = projectData.from.toDate();
            sections.project.to = projectData.to.toDate();
            sections.project.budget = projectData.budget;
            sections.project.unforeseenExpenses = projectData.unforeseenExpenses ?? 10;
            if (projectData.image?.path) sections.project.image = await downloadFile(projectData.image.path);

            sections.timeline.percent = computed(() => {
                const timelineDays = differenceInDays(sections.project.to, sections.project.from);
                const alreadyPastDays = differenceInDays(new Date(), sections.project.from);
                const percent = alreadyPastDays > 0 ? Math.round((alreadyPastDays / timelineDays) * 100) : 0;
                return percent > 100 ? 100 : percent;
            });

            sections.project.isLoading = false;
        });

        return { ...toRefs(sections) };
    },
};
</script>

<style scoped lang="less">
@import '../assets/css/variables';

main.grid {
    > section {
        @media @laptop-max-screen {
            &:not(:last-child) {
                margin-bottom: calc(var(--container-padding) * 2);
            }
        }

        @media @laptop-screen {
            padding: 35px 35px 20px;

            &:not(.naked) {
                background: rgb(var(--light-grey-color));
                border-radius: 30px;
            }
        }

        header.card {
            &.labels {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;

                .status-label {
                    min-width: unset;
                    padding: 0 15px;
                    font-size: 1.1rem;
                }
            }

            p.columns {
                @media @full-desktop-max-screen {
                    display: unset;

                    span:last-child {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        text-align: left;

                        > * {
                            margin: 0;
                        }

                        .larger {
                            grid-row: 2;
                        }

                        br {
                            display: none;
                        }
                    }
                }

                span:last-child {
                    white-space: nowrap;
                }
            }
        }
    }
}

h2 {
    font-size: 3.6rem;
    line-height: 1.05;
    font-weight: var(--semibold-weight);
    margin: 0 0 1rem;

    small {
        font-size: 2.4rem;
    }
}

h3 {
    font-weight: var(--semibold-weight);
    margin: 2.2rem 0 0;
    font-size: 2.4rem;
    line-height: 1.1;
}

section {
    &.empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media @laptop-max-screen {
            background: rgb(var(--light-grey-color));
            border-radius: 30px;
            padding: 40px;
        }

        @media @laptop-screen {
            svg.illustration {
                width: 55%;
            }
        }
    }

    &.double {
        @media @large-desktop-max-screen {
            grid-row: 2;
        }

        @media @laptop-max-screen {
            section.empty {
                margin-top: var(--container-padding);
            }
        }
    }

    @media @laptop-screen {
        &.double {
            grid-column: span 2;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 80px;
        }
    }

    &.progress {
        .space-top {
            display: inline-block;
            margin-top: 1rem;
        }

        .explainer {
            color: rgb(var(--grey-color));

            @media @full-desktop-screen {
                margin: 0;
            }
        }

        .percent {
            font-size: 12.4rem;
            line-height: 0.87;
            color: rgb(var(--primary-color));
            letter-spacing: -0.08em;
        }
    }

    &.project {
        position: relative;
        padding: 0 !important;
        overflow: hidden;
        display: flex;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        h2 {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
            margin: 0;
            color: rgb(var(--white-color));
            padding: 20px;
        }

        button {
            margin: auto;
            min-width: 200px;
        }
    }

    ul.card-list li.card {
        border: 1px solid rgb(var(--semi-light-grey-color));
        background: rgb(var(--white-color));
    }
}

div.gantt-chart {
    margin-bottom: 0;
}

progress {
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    border-radius: 13px;
    overflow: hidden;
    width: 100%;
    margin-top: 1.3rem;

    &::-webkit-progress-bar {
        background-image: linear-gradient(to right, rgb(var(--red-status-text-color)), rgb(var(--red-status-background-color)));
    }

    &::-webkit-progress-value {
        background: rgb(var(--green-status-text-color));
    }
}

img {
    max-width: 100%;
}

</style>
