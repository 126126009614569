<template>
    <Card @click="click()"
          :class="{ 'compact' : compact }"
          :status-text="!compact && statusText"
          :status-color="!compact && statusColor"
          :column="column">

        <template #headline>
            <span>{{ subject }}</span>
        </template>
        <time>{{ formatDateToFnsDate(new Date(created_at), compact ? "d. LLLL" : "d. LLLL yyyy 'kl.' HH.mm") }}</time>
        <p v-if="!compact">{{ description }}</p>
    </Card>
</template>

<script>
import Card from '@/components/Card.vue';
import methods from '@/utils/methods';
import useState from '@/store';

export default {
    name: 'QuestionCard',
    components: {
        Card,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        subject: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        created_at: {
            type: String,
            default: null,
        },
        column: {
            type: Number,
            default: 1,
        },
        statusColor: {
            type: String,
            default: 'grey',
        },
        statusText: {
            type: String,
            default: 'Kladde',
        },
        status: {
            type: String,
            default: 'new',
        },
        compact: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { setModalData } = useState();
        return { setModalData };
    },
    mixins: [methods],
    methods: {
        click() {
            const data = this.removeNullValues(this.$props);
            this.setModalData({ statusColor: this.statusColor, statusText: this.statusText, ...data });
            this.$router.push({ name: 'view-question', params: data });
        },
    },
};
</script>
